<template>
  <v-container fluid>
    <div class="d-flex mb-6" style="gap: 20px">
      <v-spacer />
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        class="mx-auto"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <h1 :class="`primary--text text-center mx-auto py-2 my-2 ${$vuetify.theme.dark ? 'pickerDark' : 'picker'}`" v-bind="attrs" v-on="on" style="width: 300px;font-size: xx-large;border-radius: 5px;">
            {{ date ? new Date(date).toLocaleDateString('esp',{ weekday :'short', day:'numeric',month:'short', year:'numeric' }).toLocaleUpperCase() : "CARGANDO..." }}
          </h1>
        </template>
        <v-date-picker
          v-model="dateAux"
          :first-day-of-week="1"
          no-title
          scrollable
          event-color="error"
          @click:date="menu = false;"
        ></v-date-picker>
      </v-menu>
      <v-spacer />
      <v-btn outlined icon :color="$vuetify.theme.dark ? '#EB44B6' : '#E252F2'" v-if="hasEvents && isNotPb" :to="`/planificacion/${ date }`" >
        <v-icon style="text-shadow: .6px .6px rgba(0, 0, 0, .7);" small>mdi-notebook-multiple</v-icon>
      </v-btn>
      <v-btn outlined icon :color="$vuetify.theme.dark ? '#EB44B6' : '#E252F2'" v-if="hasEvents && isNotPb && isCat && (!!($store.getters.getTokenPayload.permisos & $store.getters.getPermisos['NECESIDADES']))" :to="`/necesidades/${ date }`" >
        <v-icon style="text-shadow: .6px .6px rgba(0, 0, 0, .7);" small>mdi-glass-wine</v-icon>
      </v-btn>
      <v-btn outlined icon :color="$vuetify.theme.dark ? '#EB44B6' : '#E252F2'" v-if="hasEvents && isNotPb && isCat" :to="`/check_catering/${ date }`" >
        <v-icon style="text-shadow: .6px .6px rgba(0, 0, 0, .7);" small>mdi-truck</v-icon>
      </v-btn>
      <v-btn outlined :color="$vuetify.theme.dark ? '#53ADD4' : '#2491BF'" icon v-if="hasEvents && isNotPb && (!!($store.getters.getTokenPayload.permisos & $store.getters.getPermisos['CONTROL_HORAS']))" :to="`/horas/${ date }`" >
        <v-icon small style="text-shadow: .6px .6px rgba(0, 0, 0, .7);">mdi-human-male</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn @click.stop="savePlan" :loading="saving" color="primary">
        <v-icon left>mdi-content-save</v-icon>
        Guardar
      </v-btn>
    </div>
    <div class="d-flex flex-wrap mb-6" style="gap: 20px">
      <CostesHueco
        v-for="(h, i) in items"
        :key="i"
        :hueco="h"
        v-model="items[i]"
        :date="dateParsed"
      />
    </div>
    <ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import { req } from "@/utils/validations.js";
import { dateToIso } from "@/utils/index.js";

export default {
  props: {
    date: String,
  },
  components: {
    CostesHueco: () => import("../components/CostesHueco.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    AuxInput: () => import("@/components/AuxInput.vue"),
    TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
  },
  data() {
    return {
      dragover: false,
      saving: false,
      menu: false,
      rules: { req },
      items: [],
      dateAux: new Date(this.date + ' 12:00:00').toISOString().split('T')[0]
    };
  },
  watch:{
    dateAux:{
      async handler(v) {
        const confirmation = await this.$refs.confirm.open(
          "Guardar los cambios",
          "¿Quieres guardar los cambios antes de irte?",
          { color: "primary" }
        );
        if (confirmation) {
          await this.savePlan(true);
        }
        this.$set(this.$data, "dateAux", this.dateAux)
        this.$router.push({path : "/costes/" + this.dateAux}).catch(() => {})
      },
    }
  },
  computed: {
    dateParsed() {
      return new Date(this.date).toLocaleDateString();
    },
    nextDate() {
      const date = new Date(this.date);
      date.setDate(date.getDate() + 1);
      return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(
        -2
      )}-${("0" + date.getDate()).slice(-2)}`;
    },
    prevDate() {
      const date = new Date(this.date);
      date.setDate(date.getDate() - 1);
      return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(
        -2
      )}-${("0" + date.getDate()).slice(-2)}`;
    },
  },
  methods: {
    async getCostes() {
      const { data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/costes_evento/${dateToIso(this.date)}`,
      });
      this.items = data;
    },
    async savePlan(skip) {
      const confirmation =
        skip ||
        (await this.$refs.confirm.open(
          "Guardar los cambios",
          "¿Estás seguro de que quieres guardar esta planificación?",
          { color: "primary" }
        ));
      if (!confirmation) return;
      try {
        this.saving = true;
        await Promise.all([
          axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API_URL}/costes_evento/${dateToIso(this.date)}`,
            headers: { 'Cache-Control': 'no-cache' },
            data: {
              items: JSON.stringify(this.items),
            }
          })
        ])
        this.saving = false;

        this.$root.$emit("snack", "Se han guardado los cambios correctamente");
        // location.reload();
        // this.getCostes();
      } catch (err){
        this.saving = false;
        console.error(err);
        this.$root.$emit("snack", "No se han podido guardar los cambios");
      }
		},
    hasEvents() {
      return this.items.length > 0;
    },
    isNotPb() {
      return this.items.some((ev) => ["Boda","Comunión","Evento","Corporativo"].includes(ev.tipo)).length > 0;
    },
    isCat() {
      console.log(this.huecos.filter((ev) => ev.nombreEspacio == "CATERING" ).length > 0);
      return (this.huecos.filter((ev) => ev.nombreEspacio == "CATERING" ).length > 0);
    },
  },
  mounted() {
    this.getCostes();
    // const self = this
		// this.autosave = setInterval( async function () {
		// 	await self.savePlan(true);
		// }, 1000 * 60 )
  },
  async beforeRouteLeave(to, from, next) {
    const confirmation = await this.$refs.confirm.open(
      "Guardar los cambios",
      "¿Quieres guardar los cambios antes de irte?",
      { color: "primary" }
    );
    if (confirmation) {
      await this.savePlan(true);
    }
    next();
  },
	beforeDestroy() {
		// clearInterval( this.autosave )
	}
};
</script>

<style>
</style>